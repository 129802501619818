@charset "UTF-8";
/* *****************************************************************
 *  Theme:    Agnosys
 *  Author:   Rubikon
 *  URI: http://www.rubikon.at
 *  ***************************************************************** */
/* _variables.scss */
/* _typography.scss */
h1, h2, h3, h4, h5 {
  font-family: "URW Geometric W01 Light", sans-serif;
  margin-top: 2rem;
  margin-bottom: 1.5rem; }

p, li, a {
  font-family: "URW Geometric W01 Regular", sans-serif; }

blockquote p {
  font-family: "URW Geometric W01 Light", sans-serif; }

/* _header.scss */
header {
  background: #101845;
  background: -moz-linear-gradient(left, #101845 0%, #101845 50%, #ffffff 50%, #ffffff 100%);
  background: -webkit-linear-gradient(left, #101845 0%, #101845 50%, #ffffff 50%, #ffffff 100%);
  background: linear-gradient(to right, #101845 0%, #101845 50%, #ffffff 50%, #ffffff 100%); }
  header .container {
    background-color: #FFFFFF; }

.logo {
  background-color: #101845; }
  .logo a {
    height: 100%;
    width: 100%;
    display: block; }
  .logo img {
    padding: 1rem;
    padding-left: 0;
    width: 12rem; }

nav.main .menu {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  position: relative; }
  nav.main .menu > li {
    padding-bottom: 15px; }
  nav.main .menu > li > a:hover, nav.main .menu > li.current-menu-item > a, nav.main .menu > li.current-menu-parent > a, nav.main .menu > li.current-page-ancestor > a {
    border-bottom: 3px solid #B1CD42; }
  nav.main .menu > .menu-item > .sub-menu > li:last-child {
    margin-left: 2rem; }
  nav.main .menu .sub-menu {
    position: absolute;
    display: none;
    background-color: #FFFFFF;
    padding: 1rem;
    z-index: 11 !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1); }
    nav.main .menu .sub-menu a {
      color: #0091D3;
      display: block;
      margin-bottom: 0.5rem;
      font-size: 0.9rem; }
    nav.main .menu .sub-menu .sub {
      color: #101845;
      font-size: 0.8rem;
      line-height: 1;
      display: block;
      margin-bottom: 0.5rem; }
    nav.main .menu .sub-menu .sub-menu {
      flex-direction: column;
      position: static;
      box-shadow: 0 0 0 transparent;
      padding: 0; }
  nav.main .menu a {
    font-size: 1.1rem; }
    nav.main .menu a:hover {
      text-decoration: none; }

nav.meta {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between; }
  nav.meta #menu-meta-menu {
    display: flex; }
    nav.meta #menu-meta-menu li {
      margin-right: 1rem; }
  nav.meta a {
    color: #0091D3;
    font-size: 0.8rem; }

nav.meta, nav.main .menu {
  display: none; }
  @media (min-width: 992px) {
    nav.meta, nav.main .menu {
      display: flex; } }

@media (min-width: 992px) {
  nav.main > .menu-menu-container > .menu > li > .sub-menu > li {
    flex: 1; } }

.burger-button {
  display: block;
  margin-right: auto;
  font-weight: bold;
  font-size: 1.4rem;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 25px;
  margin-top: auto;
  margin-bottom: auto; }
  @media (min-width: 992px) {
    .burger-button {
      display: none; } }
  .burger-button img {
    width: 25px;
    height: auto;
    margin-right: 10px;
    position: relative;
    top: -3px; }

.overlay-mobile-menu {
  position: fixed;
  height: 100%;
  width: 100%;
  padding-top: 4.5rem;
  text-align: center;
  transform: translate3D(0, -100%, 0);
  background-color: #B1CD42;
  transition: 0.2s transform;
  top: 0;
  left: 0; }
  .overlay-mobile-menu ul {
    padding-left: 0; }
  .overlay-mobile-menu h3 {
    font-size: 2.2rem;
    margin-bottom: 0.9rem;
    color: #FFFFFF; }
  .overlay-mobile-menu li {
    margin-bottom: 0.7rem;
    list-style-type: none; }
  .overlay-mobile-menu a {
    font-size: 1.3rem;
    color: #FFFFFF; }
    .overlay-mobile-menu a:hover {
      text-decoration: none;
      color: #0091D3; }
  .overlay-mobile-menu .sub-menu {
    display: none; }
    .overlay-mobile-menu .sub-menu a {
      font-size: 1.2rem; }
  .overlay-mobile-menu .close-btn {
    font-size: 3rem;
    opacity: 0.7;
    font-weight: 100;
    position: absolute;
    right: 20px;
    top: 0; }

.sub-menu.opened {
  display: block;
  margin-bottom: 1.5rem;
  opacity: 0.8;
  margin-top: 1rem; }

.overlay-mobile-menu.open {
  transform: translate3D(0, 0, 0); }

nav.main .menu li:hover .sub-menu {
  display: flex;
  transition: 0.3s;
  width: 70%;
  margin-top: 15px; }

#menu-language-switcher, #menu-language-switcher-1 {
  display: flex; }
  #menu-language-switcher li a, #menu-language-switcher-1 li a {
    color: #ABAAAC;
    font-size: .8rem; }
  #menu-language-switcher li.wpml-ls-current-language a, #menu-language-switcher-1 li.wpml-ls-current-language a {
    color: #0091D3; }
  #menu-language-switcher li:last-child::before, #menu-language-switcher-1 li:last-child::before {
    content: "|";
    color: #ABAAAC;
    margin-right: 0.2rem;
    margin-left: 0.2rem; }

#menu-language-switcher-1 {
  justify-content: center; }
  @media (min-width: 768px) {
    #menu-language-switcher-1 {
      justify-content: flex-start; } }

.carousel-caption {
  top: 0;
  text-align: left;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-left: 0.9rem; }
  @media (min-width: 992px) {
    .carousel-caption {
      width: 870px; } }
  .carousel-caption h1 {
    width: 15rem;
    font-weight: bold;
    font-size: 2rem; }
    @media (min-width: 992px) {
      .carousel-caption h1 {
        font-size: 2.8rem; } }
  .carousel-caption p {
    font-family: "URW Geometric W01 Light", sans-serif; }

.carousel-indicators {
  bottom: 1.5rem;
  text-align: left;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-left: 2rem;
  justify-content: flex-start; }
  @media (min-width: 992px) {
    .carousel-indicators {
      width: 870px; } }
  .carousel-indicators li {
    background-color: #FFFFFF; }
  .carousel-indicators .active {
    background-color: #0091D3; }

.contact-box {
  position: fixed;
  z-index: 2;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 60%;
  background-color: #101845;
  height: 130px;
  width: 40px;
  opacity: 0.9; }
  @media (min-width: 992px) {
    .contact-box {
      width: 62px;
      height: 170px;
      opacity: 1;
      top: 40%; } }
  .contact-box .icon img {
    width: 25px;
    margin-top: 1.5rem;
    margin-bottom: 2rem; }
    @media (min-width: 992px) {
      .contact-box .icon img {
        width: 38px; } }
  .contact-box .text {
    transform: rotate(-90deg);
    color: #FFFFFF; }

.points-animation-container {
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: none; }
  @media (min-width: 992px) {
    .points-animation-container {
      width: 870px;
      display: block; } }
  .points-animation-container ul {
    margin-left: auto;
    width: 24rem;
    margin-top: 3rem; }
  .points-animation-container li {
    width: 10px;
    height: 10px;
    margin: 1rem;
    background-color: #FFFFFF;
    border-radius: 10px;
    display: inline-block;
    opacity: 0;
    transition: 0.3s opacity;
    box-shadow: 0 0 4px rgba(16, 24, 69, 0.44); }
  .points-animation-container .blink-red {
    background-color: red;
    animation: blink 0.6s linear infinite; }
  .points-animation-container .blink-green {
    background-color: #B1CD42;
    animation: blink 0.6s linear infinite; }

@keyframes blink {
  50% {
    opacity: 0; } }

/* _start.scss */
.start p a {
  color: #101845;
  text-decoration: underline; }
  .start p a:hover {
    text-decoration: none;
    color: #101845; }

#carousel-start {
  height: 400px;
  width: 100%;
  margin-bottom: 2rem; }
  @media (min-width: 992px) {
    #carousel-start {
      height: 700px;
      width: 100%; } }
  #carousel-start .carousel-inner, #carousel-start .carousel-item {
    height: 100%;
    width: 100%; }
  #carousel-start .carousel-item img {
    height: 400px;
    width: 100%;
    object-fit: cover;
    display: none; }
    @media (min-width: 992px) {
      #carousel-start .carousel-item img {
        height: 700px;
        width: 100%; } }

#carousel-content {
  height: auto;
  margin-bottom: 2rem;
  margin-top: 2rem; }
  #carousel-content .carousel-inner {
    display: flex;
    align-items: center; }
  #carousel-content .carousel-inner, #carousel-content .carousel-item {
    height: 100%;
    width: 100%; }
  #carousel-content .carousel-item-next, #carousel-content .carousel-item-prev, #carousel-content .carousel-item.active {
    flex-direction: column; }
  #carousel-content p a {
    color: #101845;
    text-decoration: underline; }
    #carousel-content p a:hover {
      text-decoration: none;
      color: #101845; }

#carousel-content .carousel-item {
  flex-direction: column;
  justify-content: center; }
  @media (min-width: 992px) {
    #carousel-content .carousel-item {
      min-height: 250px; } }
  #carousel-content .carousel-item p {
    text-align: center;
    margin: 0 auto; }
  #carousel-content .carousel-item img {
    height: auto;
    width: 100%;
    object-fit: cover;
    margin: 0 auto; }
    @media (min-width: 992px) {
      #carousel-content .carousel-item img {
        height: auto;
        width: 100%; } }

/* _standard.scss */
.standard p a {
  color: #101845;
  text-decoration: underline; }
  .standard p a:hover {
    color: #101845;
    text-decoration: none; }

#carousel-standard {
  height: auto;
  width: 100%;
  margin-bottom: 2rem; }
  @media (min-width: 992px) {
    #carousel-standard {
      height: 350px;
      width: 100%; } }
  #carousel-standard .carousel-inner, #carousel-standard .carousel-item {
    height: 100%;
    width: 100%; }
  #carousel-standard .carousel-item img {
    height: 200px;
    width: 100%;
    display: none;
    object-fit: cover; }
    @media (min-width: 992px) {
      #carousel-standard .carousel-item img {
        height: 350px;
        width: 100%; } }
  #carousel-standard h1 {
    color: #101845;
    font-family: "URW Geometric W01 Regular", sans-serif; }
  #carousel-standard .carousel-caption h1 {
    width: 20rem; }

.carousel-caption {
  z-index: 3; }

.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity; }

.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-left,
.carousel-fade .carousel-item-prev.carousel-item-right {
  opacity: 1; }

.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-right {
  opacity: 0; }

.carousel-fade .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade .active.carousel-item-prev {
  transform: translateX(0);
  transform: translate3d(0, 0, 0); }

.carousel-item img {
  opacity: 0; }

.carousel-item img.fade-in {
  opacity: 1; }

.carousel-caption p {
  font-size: 1.5rem;
  font-family: "URW Geometric W01 Light", sans-serif;
  font-weight: 100;
  line-height: 1.2; }

.standard-content a {
  color: #101845;
  text-decoration: underline; }
  .standard-content a:hover {
    text-decoration: none;
    color: #101845; }

.collapse-btn {
  margin-bottom: 1rem;
  display: block;
  background: url(images/collapse-btn.svg) no-repeat center center;
  background-size: auto 23px;
  text-align: center;
  color: #FFFFFF;
  font-weight: normal;
  width: 100%;
  height: 23px;
  font-size: 15px; }
  .collapse-btn:hover, .collapse-btn:active, .collapse-btn:focus {
    text-decoration: none;
    color: #FFFFFF; }

.collapse, .card {
  background-color: #EAEAEA;
  border: 0;
  margin-bottom: 1rem; }
  .collapse a, .card a {
    color: #101845;
    text-decoration: underline; }
    .collapse a:hover, .card a:hover {
      text-decoration: none;
      color: #101845; }

.card {
  font-size: 0.9rem; }
  .card ul {
    list-style-image: none !important;
    padding-left: 0 !important; }
    .card ul li:before {
      content: "> "; }
  .card h3 {
    margin-top: 0;
    margin-bottom: 0.7rem;
    font-size: 1.4rem; }

.introductions-small {
  text-align: center;
  font-size: 1.3rem;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 2rem;
  margin-bottom: 2rem; }
  .introductions-small a {
    color: #101845;
    text-decoration: underline; }
    .introductions-small a:hover {
      text-decoration: none;
      color: #101845; }

.big-box-image {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center; }

.downloads #accordion {
  margin-top: 3rem; }

.downloads .card {
  background: transparent; }

.downloads .collapse {
  background: transparent; }

.downloads .card-header {
  background: transparent;
  padding-left: 0; }

.downloads .download-file-container {
  box-shadow: 0 0 0 transparent !important; }

.downloads .btn {
  padding: 0;
  font-size: 2rem;
  font-weight: 500;
  color: #101845;
  font-family: "URW Geometric W01 Light", sans-serif; }

.downloads h3 {
  margin-top: 2rem !important;
  margin-bottom: 1.5rem !important; }

.tooltip-container {
  position: relative;
  height: 220px;
  width: 220px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 3rem;
  margin-bottom: 3rem; }
  @media (min-width: 576px) {
    .tooltip-container {
      height: 310px;
      width: 310px; } }
  @media (min-width: 992px) {
    .tooltip-container {
      height: 370px;
      width: 370px; } }
  .tooltip-container .tooltip-item {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute; }
    @media (min-width: 576px) {
      .tooltip-container .tooltip-item {
        width: 120px;
        height: 120px; } }
    @media (min-width: 992px) {
      .tooltip-container .tooltip-item {
        width: 150px;
        height: 150px; } }
  .tooltip-container .tooltip-button {
    width: 20px;
    height: 20px;
    z-index: 20; }
    @media (min-width: 576px) {
      .tooltip-container .tooltip-button {
        width: 30px;
        height: 30px; } }
    @media (min-width: 992px) {
      .tooltip-container .tooltip-button {
        width: 40px;
        height: 40px; } }
    .tooltip-container .tooltip-button img {
      width: 40px;
      height: 40px;
      animation-name: tooltip-button;
      animation-duration: 1.5s;
      animation-iteration-count: infinite; }
  .tooltip-container .tooltip-custom {
    width: 60px;
    height: 60px;
    position: absolute;
    z-index: 30;
    color: #FFFFFF;
    top: 10px;
    display: none;
    align-items: center;
    justify-content: center;
    line-height: 1;
    font-size: 0.7rem;
    text-align: center; }
    .tooltip-container .tooltip-custom p {
      margin: 0;
      font-family: "URW Geometric W01 Bold", sans-serif; }
    @media (min-width: 576px) {
      .tooltip-container .tooltip-custom {
        width: 80px;
        height: 80px;
        font-size: 0.75rem; } }
    @media (min-width: 992px) {
      .tooltip-container .tooltip-custom {
        width: 100px;
        height: 100px;
        font-size: 0.85rem; } }
  .tooltip-container .tooltip-custom.left {
    left: -30px; }
  .tooltip-container .tooltip-custom.right {
    right: -30px; }
  .tooltip-container .tooltip-custom.bottom {
    align-items: center;
    padding-top: 1.4rem; }
    @media (min-width: 576px) {
      .tooltip-container .tooltip-custom.bottom {
        padding-top: 1.7rem; } }
    @media (min-width: 992px) {
      .tooltip-container .tooltip-custom.bottom {
        padding-top: 2.1rem; } }
  .tooltip-container .tooltip-custom.center {
    align-items: center;
    padding-bottom: 0.4rem; }
    @media (min-width: 576px) {
      .tooltip-container .tooltip-custom.center {
        padding-bottom: 0.5rem; } }
    @media (min-width: 992px) {
      .tooltip-container .tooltip-custom.center {
        padding-bottom: 0.5rem; } }
  .tooltip-container .tooltip-custom.show {
    display: flex; }
  .tooltip-container .graphic-overlay {
    opacity: 0;
    transition: 1s; }
  .tooltip-container .graphic-overlay.show {
    opacity: 1; }
  .tooltip-container .graphic-change {
    position: absolute;
    top: -10px;
    right: -15px; }

.big-box-image .tooltip-container.facility {
  height: 300px;
  width: 300px; }
  @media (min-width: 576px) {
    .big-box-image .tooltip-container.facility {
      height: 390px;
      width: 390px; } }
  @media (min-width: 992px) {
    .big-box-image .tooltip-container.facility {
      height: 450px;
      width: 450px; } }

@keyframes tooltip-button {
  0% {
    transform: scale(1);
    opacity: 1; }
  50% {
    transform: scale(1.2);
    opacity: 1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

/* Newsletter */
.newsletter {
  margin-top: 5rem; }
  .newsletter #mce-EMAIL, .newsletter #mce-FNAME, .newsletter #mce-LNAME {
    height: 2.5rem !important;
    border: 1px solid #EAEAEA !important;
    width: 100%;
    border-radius: 0 !important; }
  .newsletter #mc_embed_signup .mc-field-group label {
    font-size: 16px !important;
    font-weight: 400 !important; }
  .newsletter .gdprRequired.mc-field-group {
    margin-bottom: 1rem; }
  .newsletter #mc-embedded-subscribe {
    background-color: #0091D3 !important;
    color: #FFFFFF !important;
    border-radius: 5px !important;
    border: 0 !important;
    margin-top: 1rem !important;
    display: inline-block !important; }
    .newsletter #mc-embedded-subscribe:hover {
      text-decoration: none !important;
      color: #FFFFFF !important;
      opacity: 0.8 !important; }
    .newsletter #mc-embedded-subscribe:focus {
      outline: none !important; }
    .newsletter #mc-embedded-subscribe:active {
      opacity: 0.6 !important; }

/* _product.scss */
.single-product ul.download-file-container {
  box-shadow: 0 0 10px #ABAAAC;
  padding: 1rem;
  padding-left: 2rem;
  list-style-image: none; }
  .single-product ul.download-file-container li {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #0091D3;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem; }
    .single-product ul.download-file-container li a {
      font-size: 0.9rem; }
  .single-product ul.download-file-container li::before {
    content: url(images/file-icon.svg);
    width: 26px;
    display: inline-block;
    margin-right: 1rem; }

.single-product h3 {
  font-size: 1.4rem;
  margin-top: 0.4rem; }

.introduction {
  text-align: center;
  font-size: 2rem; }
  .introduction p {
    font-family: "URW Geometric W01 Light", sans-serif;
    line-height: 1.2; }
    .introduction p a {
      color: #101845;
      text-decoration: underline; }
      .introduction p a:hover {
        text-decoration: none;
        color: #101845; }

.image-video-text img {
  width: 100%; }

.product-carousel .carousel-caption h1 {
  margin-top: 0;
  width: 20rem; }

.product-carousel .carousel-caption .product {
  height: 9rem !important;
  width: auto !important; }
  @media (min-width: 992px) {
    .product-carousel .carousel-caption .product {
      height: 15rem !important; } }

/* _overview.scss */
.overview ul {
  list-style-image: none !important; }

.overview li {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.overview h2 {
  margin-top: 0; }

/* _standard.scss */
/* _footer.scss */
footer {
  background-color: #EAEAEA;
  margin-top: 5rem;
  color: #ABAAAC; }
  footer a {
    color: #ABAAAC; }
  footer p {
    font-size: 0.8rem;
    margin-bottom: 0; }

.company-info {
  text-align: center; }
  @media (min-width: 768px) {
    .company-info {
      text-align: left; } }
  .company-info img {
    width: 147px;
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .company-info a {
    text-decoration: underline; }
    .company-info a:hover {
      text-decoration: none; }

nav.legal ul {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center; }
  @media (min-width: 768px) {
    nav.legal ul {
      justify-content: flex-start; } }
  nav.legal ul li {
    margin-right: 0.5rem; }
  nav.legal ul a {
    font-size: 0.8rem;
    text-decoration: underline; }

nav.footer {
  margin-top: 3.8rem; }

nav.footer .menu-menu-container > .menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; }
  @media (min-width: 768px) {
    nav.footer .menu-menu-container > .menu {
      flex-wrap: nowrap;
      justify-content: flex-start; } }
  nav.footer .menu-menu-container > .menu a {
    color: #101845; }

nav.footer .sub-menu {
  text-align: center;
  opacity: 0.8;
  font-size: 0.9rem; }
  nav.footer .sub-menu li::before {
    content: "> ";
    font-size: 0.8rem;
    color: #101845; }
  nav.footer .sub-menu .sub-menu li::before {
    content: "- ";
    color: #101845;
    font-size: 0.8rem; }
  @media (min-width: 768px) {
    nav.footer .sub-menu {
      text-align: left;
      padding-left: 0.4rem; } }

nav.footer li {
  margin-right: 2rem; }
  nav.footer li .sub-menu li {
    margin-right: 0; }

.footer-circles {
  background-image: url(images/dot.png);
  background-size: 23px;
  background-position-y: 10rem;
  height: 300px;
  margin-top: 2rem; }

html {
  font-size: 14px; }
  @media (min-width: 576px) {
    html {
      font-size: 15px; } }
  @media (min-width: 992px) {
    html {
      font-size: 16px; } }
  @media (min-width: 1200px) {
    html {
      font-size: 16px; } }

body {
  font-family: "URW Geometric W01 Regular", sans-serif;
  color: #101845; }

ul {
  padding-left: 0;
  margin-bottom: 0; }

li {
  list-style-type: none; }

a {
  color: #000000; }
  a:hover {
    color: inherit;
    text-decoration: underline; }
  a:focus {
    color: inherit;
    text-decoration: none; }

img {
  max-width: 100%;
  height: auto; }

@media (min-width: 992px) {
  .container {
    width: 870px; } }

.stripline {
  height: 20px;
  width: 100%;
  background: url(images/dot.png) repeat-x left center;
  background-size: 23px;
  margin-top: 1.5rem;
  margin-bottom: 0; }

.fade-in {
  display: block !important;
  transition: 1s; }

section.container ul {
  padding-left: 1.1rem;
  list-style-image: url(images/list-icon.png); }

blockquote {
  font-size: 1.3rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  line-height: 1.2; }
  @media (min-width: 992px) {
    blockquote {
      font-size: 2rem;
      text-align: center;
      width: 90%; } }
  blockquote:before {
    display: inline;
    content: "„"; }
  blockquote:after {
    display: inline;
    content: "“"; }
  blockquote p {
    display: inline; }

.standard blockquote {
  margin-top: 1rem; }

.author {
  color: #0091D3;
  font-size: 1rem;
  display: block;
  font-family: "URW Geometric W01 Light", sans-serif;
  margin-top: 0.7rem; }
  @media (min-width: 992px) {
    .author {
      font-size: 1.3rem; } }

.breadcrumbs {
  font-size: 0.8rem;
  position: relative;
  top: -22px;
  color: #0091D3; }
  .breadcrumbs a {
    color: #101845; }

.wpcf7-form label {
  width: 100%; }

.wpcf7-text {
  height: 2.5rem;
  border: 1px solid #EAEAEA;
  width: 100%; }

.wpcf7-textarea {
  height: 8.5rem;
  border: 1px solid #EAEAEA;
  width: 100%; }

.button,
.wpcf7-submit {
  background-color: #0091D3;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  color: #FFFFFF;
  border-radius: 5px;
  border: 0;
  margin-top: 1rem;
  display: inline-block; }
  .button:hover,
  .wpcf7-submit:hover {
    text-decoration: none;
    color: #FFFFFF;
    opacity: 0.8; }
  .button:focus,
  .wpcf7-submit:focus {
    outline: none !important; }
  .button:active,
  .wpcf7-submit:active {
    opacity: 0.6; }

.wpcf7-submit {
  margin-top: 0; }

div.wpcf7-response-output {
  margin: 0 !important;
  font-size: 1rem;
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important; }

div.wpcf7-validation-errors {
  background-color: red;
  color: #FFFFFF;
  border: 0 !important; }

div.wpcf7-mail-sent-ok {
  background-color: green;
  color: #FFFFFF;
  border: 0 !important; }

/* referenzmap */
.refMapBlock {
  height: 2000px;
  width: 100%;
  position: relative;
  float: none;
  margin: -32px 0 -79px 0; }

.Refmap .standard .stripline {
  display: none; }

.text_blue,
.text_blue a {
  color: #0091D3; }

.text_green,
.text_green a {
  color: #B1CD42; }

.MapInfoLable {
  font-family: URW Geometric W01 Light, sans-serif;
  position: static;
  z-index: 999;
  margin-top: 3rem; }
  @media (min-width: 992px) {
    .MapInfoLable {
      position: absolute; } }

.MapInfoLable h1 {
  font-size: 28px;
  margin: 0 0 20px 0; }

.MapInfoLable h3 {
  font-size: 16px;
  font-weight: bold;
  font-family: URW Geometric W01 Regular, sans-serif;
  margin: 0 0 0 0; }

.MapInfoLable .hqInfo {
  font-size: 16px;
  font-family: URW Geometric W01 Regular, sans-serif; }

.hqInfo {
  margin: 0 0 0 16px; }

.MapInfoLine {
  margin: 0px 0 5px 0; }

.MapInfoLineHQ {
  margin: 20px 0 0 0;
  line-height: 22px; }

.MapInfoLineHQ a {
  text-decoration: underline; }

.MapInfoLable .bullet {
  width: 11px;
  height: 11px;
  float: left;
  margin: 4px 5px 0 0;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px; }

.square {
  width: 11px;
  height: 11px;
  float: left;
  margin: 4px 5px 0 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px; }

.bullet_blue {
  background: #0091D3; }

.bullet_green {
  background: #B1CD42; }

.bullet_black {
  background: #000000; }

.worldmapInner {
  height: 2000px;
  width: 1360px;
  max-width: 100%;
  margin: 0 auto;
  position: relative; }

img.worldmap2 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: .15 !important;
  width: 100%; }

.markerItembox {
  position: absolute;
  z-index: 5;
  width: 15px;
  height: 15px;
  margin: 35px 0 0 10px; }

.mapmarker {
  z-index: 9999 !important;
  display: none;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  width: 15px;
  height: 15px;
  -moz-transition: all 250ms;
  -o-transition: all 250ms;
  transition: all 250ms;
  left: -12px;
  top: -33px; }

.markerItembox:hover .mapmarker {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.21);
  -moz-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.21);
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.21); }

.ReferenzDetailLayer {
  position: fixed;
  right: -1000%;
  top: 20%;
  background: #ffffff;
  z-index: 999999;
  width: 374px;
  height: auto;
  opacity: 0;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.11);
  -moz-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.11);
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.11);
  -webkit-transition: opacity 350ms;
  -moz-transition: opacity 350ms;
  -o-transition: opacity 350ms;
  transition: opacity 350ms; }

.ReferenzDetailLayerOn {
  position: fixed;
  right: 20%;
  top: 20%;
  opacity: 1; }

.ReferenzDetailLayerInner {
  width: 90%;
  margin: 20px auto 30px auto; }

.ReferenzDetailLayer h2 {
  font-size: 28px;
  margin: 0 0 20px 0; }

.ReferenzDetailLayer h3 {
  font-size: 16px;
  font-weight: bold;
  font-family: URW Geometric W01 Regular, sans-serif;
  margin: 0 0 0 0; }

.refkontaktItem {
  margin: 0 0 20px 0;
  width: 100%; }

.closeLayer {
  display: block;
  cursor: pointer;
  opacity: 0.2;
  float: right;
  margin: 0 0 0 0;
  width: 30px;
  height: 30px;
  -webkit-transition: opacity 350ms;
  -moz-transition: opacity 350ms;
  -o-transition: opacity 350ms;
  transition: opacity 350ms; }

.closeLayer:hover {
  opacity: 1; }

.refCount {
  cursor: pointer;
  position: absolute;
  color: #ffffff;
  top: -36.5px;
  left: -8px;
  z-index: 99999;
  font-size: 13px; }

.blue-text {
  color: #0091D3 !important; }

.privacy-policy-notice {
  font-size: 0.8rem; }
  .privacy-policy-notice a {
    color: #0091D3; }

/* Styles from Gerald */
.big-box-image .tooltip-container.facility {
  height: auto;
  width: 560px;
  max-width: 100%; }

.contact-box {
  z-index: 10; }

.single-product ul.download-file-container {
  margin-bottom: 1rem; }

.tooltip-container .tooltip-custom p {
  display: block;
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  font-family: URW Geometric W01 Regular, sans-serif;
  /* BODL ist in IE 10 nicht sichtbar */ }

.tooltip-container .tooltip-custom.bottom p {
  top: calc(50% + 1.1em); }

.markerItembox.active {
  z-index: 66; }

.single-product ul.download-file-container li {
  margin-bottom: 14px;
  /* IE Fix */
  padding-bottom: 7px;
  /* IE Fix */
  position: relative;
  padding-left: 3rem; }

.single-product ul.download-file-container li a {
  padding: .75rem 0;
  display: block; }

.single-product ul.download-file-container li::before {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block; }

.ReferenzDetailLayerOn {
  max-width: 100%; }

.disclaimer {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: .25rem .75rem;
  background: rgba(255, 255, 255, 0.7);
  font-size: 13px; }

.disclaimer p {
  margin: 0;
  padding: 0; }

input[type="checkbox"] + label {
  padding-left: 1rem;
  margin-top: .25rem; }

#mc_embed_signup input.mce_inline_error {
  border-color: #6B0505; }

div.mce_inline_error {
  margin: 0 0 1em 0;
  padding: 5px 10px;
  background-color: #6B0505;
  font-weight: bold;
  z-index: 1;
  color: #fff; }

#mc_embed_signup a {
  text-decoration: underline; }

.overlay-mobile-menu.open nav.meta {
  display: block; }

.overlay-mobile-menu.open #menu-language-switcher-1 {
  -ms-flex-pack: center;
  justify-content: center; }

.overlay-mobile-menu.open #menu-language-switcher-1 li.wpml-ls-current-language a,
.overlay-mobile-menu.open nav.meta a {
  color: white; }

.overlay-mobile-menu {
  overflow: scroll; }

.card {
  display: block; }

.MapInfoLable {
  z-index: -1; }

#carousel-standard .carousel-item img,
.carousel-item img.fade-in {
  opacity: .8; }

#carousel-standard .carousel-item {
  background: black; }

.sidebar {
  border-right: 1px solid #0091d3;
  padding: 1rem;
  position: fixed;
  top: 10rem;
  padding-right: 3rem; }

.sidebar a {
  text-decoration: underline; }

.filename {
  color: #0091d3; }

.sidebar a:hover {
  color: #b1cd42; }

@media (max-width: 767px) {
  .ReferenzDetailLayerOn {
    right: 0; }
  .sidebar {
    position: relative;
    top: 0;
    padding: 0;
    padding-top: 3rem; } }

@media (min-width: 992px) {
  .product-carousel .carousel-caption .product {
    height: 13rem !important; } }

.termin-card {
  background-color: #fff; }
  .termin-card .icon-container {
    display: flex;
    align-items: start;
    margin-bottom: 20px; }
    .termin-card .icon-container img {
      width: 23px;
      margin-left: 10px;
      margin-right: 20px; }
  .termin-card hr {
    border-color: #0091D3; }
  .termin-card .link {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    margin-left: 52px; }
  .termin-card .termin {
    padding: 20px 20px 20px 0px;
    border-radius: 5px; }
    .termin-card .termin:hover {
      background-color: #ededed; }

/*
Yolo man, i just kicked out your //Object fit fallback because of lack of IE!
*/
