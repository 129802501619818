/* _standard.scss */

.standard p a {
  color: $blue;
  text-decoration: underline;
  &:hover {
    color: $blue;
    text-decoration: none;
  }
}

#carousel-standard {
  height: auto;
  width: 100%;
  margin-bottom: 2rem;
  @include media-breakpoint-up(lg) {
    height: 350px;
    width: 100%;
  }

  .carousel-inner, .carousel-item {
    height: 100%;
    width: 100%;
  }

  .carousel-item img {
    height: 200px;
    width: 100%;
    display: none;
    object-fit: cover;
    @include media-breakpoint-up(lg) {
      height: 350px;
      width: 100%;
    }
  }

  h1 {
    color: $blue;
    font-family: $font-family-regular;
  }

  .carousel-caption h1 {
    width: 20rem;
  }

}

.carousel-caption {
  z-index: 3;
}


.carousel-fade .carousel-item {
  opacity: 0;
  transition-duration: .6s;
  transition-property: opacity;
 }
 
 .carousel-fade  .carousel-item.active,
 .carousel-fade  .carousel-item-next.carousel-item-left,
 .carousel-fade  .carousel-item-prev.carousel-item-right {
   opacity: 1;
 }
 
 .carousel-fade .active.carousel-item-left,
 .carousel-fade  .active.carousel-item-right {
  opacity: 0;
 }
 
 .carousel-fade  .carousel-item-next,
 .carousel-fade .carousel-item-prev,
 .carousel-fade .carousel-item.active,
 .carousel-fade .active.carousel-item-left,
 .carousel-fade  .active.carousel-item-prev {
  transform: translateX(0);
  transform: translate3d(0, 0, 0);
 }
 
 .carousel-item img {
   opacity: 0;
 }

 .carousel-item img.fade-in {
  opacity: 1;
 }

.carousel-caption p {
  font-size: 1.5rem;
  font-family: $font-family-light; 
  font-weight: 100;
  line-height: 1.2;
}

.standard-content {
  a {
    color: $blue;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: $blue;
    }
  }
}

.collapse-btn {
  margin-bottom: 1rem;
  display: block;
  background: url(images/collapse-btn.svg) no-repeat center center;
  background-size: auto 23px;
  text-align: center;
  color: $white;
  font-weight: normal;
  width: 100%;
  height: 23px;
  font-size: 15px;
  &:hover, &:active, &:focus {
    text-decoration: none;
    color: $white;
  }
}

.collapse, .card {
  background-color: $grey-light;
  border: 0;
  margin-bottom: 1rem;
  a {
    color: $blue;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: $blue;
    }
  }
}

.card { 
  font-size: 0.9rem;
  ul {
     list-style-image: none !important;
     padding-left: 0 !important;
     li:before {
       content: "> ";
     }
  }
  h3 {
    margin-top: 0;
    margin-bottom: 0.7rem;
    font-size: 1.4rem;
  }
}

.introductions-small {
  text-align: center;
  font-size: 1.3rem;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  a {
    color: $blue;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: $blue;
    }
  }
}

.big-box-image {
  margin-top: 2rem; 
  margin-bottom: 2rem;
  text-align: center;
}

.downloads {
  #accordion {
    margin-top: 3rem;
  }
  .card {
    background: transparent;
  }
  .collapse {
    background: transparent;
  }
  .card-header {
    background: transparent;
    padding-left: 0;
  }
  .download-file-container {
    box-shadow: 0 0 0 transparent !important;
  }
  .btn {
    padding: 0;
    font-size: 2rem;
    font-weight: 500;
    color: $blue;
    font-family: $font-family-light;
  }
  h3 {
    margin-top: 2rem !important;
    margin-bottom: 1.5rem !important;
  }
}

.tooltip-container {
  position: relative;
  height: 220px;
  width: 220px;
  margin-right: auto; 
  margin-left: auto;
  margin-top: 3rem;
  margin-bottom: 3rem;
  @include media-breakpoint-up(sm) {
    height: 310px;
    width: 310px
  }
  @include media-breakpoint-up(lg) {
    height: 370px;
    width: 370px;
  }
  .tooltip-item {
    width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    @include media-breakpoint-up(sm) {
      width: 120px;
      height: 120px;
    }
    @include media-breakpoint-up(lg) {
      width: 150px;
      height: 150px;
    }
  }
  .tooltip-button {
    width: 20px;
    height: 20px;
    z-index: 20;
    @include media-breakpoint-up(sm) {
      width: 30px;
      height: 30px;
    }
    @include media-breakpoint-up(lg) {
      width: 40px;
      height: 40px;
    }
    img {
      width: 40px;
      height: 40px;
      animation-name: tooltip-button;
      animation-duration: 1.5s;
      animation-iteration-count: infinite;
    }
  }
  .tooltip-custom {
    width: 60px; 
    height: 60px;
    position: absolute;
    z-index: 30;
    color: $white;
    top: 10px;
    display: none;
    align-items: center; 
    justify-content: center;
    line-height: 1;
    font-size: 0.7rem;
    text-align: center;
    p {
      margin: 0;
      font-family: $font-family-bold;
    }
    @include media-breakpoint-up(sm) {
      width: 80px; 
      height: 80px;
      font-size: 0.75rem;
    }
    @include media-breakpoint-up(lg) {
      width: 100px; 
      height: 100px;
      font-size: 0.85rem;
    }
  }
  .tooltip-custom.left {
    left: -30px;
  }
  .tooltip-custom.right {
    right: -30px;
  }
  .tooltip-custom.bottom {
    align-items: center;
    padding-top: 1.4rem;
    @include media-breakpoint-up(sm) {
      padding-top: 1.7rem;
    }
    @include media-breakpoint-up(lg) {
      padding-top: 2.1rem;
    }
  }
  .tooltip-custom.center {
    align-items: center;
    padding-bottom: 0.4rem;
    @include media-breakpoint-up(sm) {
      padding-bottom: 0.5rem;
    }
    @include media-breakpoint-up(lg) {
      padding-bottom: 0.5rem;
    }
  }
  .tooltip-custom.show {
    display: flex;
  }

  .graphic-overlay {
    opacity: 0;
    transition: 1s;
  }
  .graphic-overlay.show {
    opacity: 1;
  }
  .graphic-change {
    position: absolute;
    top: -10px;
    right: -15px;
  }
}


.big-box-image .tooltip-container.facility {
  height: 300px;
  width: 300px;
  @include media-breakpoint-up(sm) {
    height: 390px;
    width: 390px
  }
  @include media-breakpoint-up(lg) {
    height: 450px;
    width: 450px;
  }
}

@keyframes tooltip-button {
  0% {transform: scale(1); opacity: 1;}
  50% {transform: scale(1.2); opacity: 1;}
  100% {transform: scale(1); opacity: 1;}
}



/* Newsletter */
.newsletter {
  margin-top: 5rem;
  #mce-EMAIL, #mce-FNAME, #mce-LNAME {
    height: 2.5rem !important;
    border: 1px solid $grey-light !important;
    width: 100%;
    border-radius: 0 !important;
  }
  #mc_embed_signup .mc-field-group label {
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  .gdprRequired.mc-field-group {
    margin-bottom: 1rem;
  }
  #mc-embedded-subscribe {
    background-color: $blue-light !important;
    color: $white !important;
    border-radius: 5px !important;
    border: 0 !important;
    margin-top: 1rem !important;
    display: inline-block !important;
    &:hover {
      text-decoration: none !important;
      color: $white !important;
      opacity: 0.8 !important;
    }
    &:focus {
      outline: none !important;
    }
    &:active {
      opacity: 0.6 !important;
    }
  }
}