/* _footer.scss */

footer {
  background-color: $grey-light;
  margin-top: 5rem;
  color: $grey;
  a {
    color: $grey;
  }
  p {
    font-size: 0.8rem;
    margin-bottom: 0;
  }
}

.company-info {
  text-align: center;
  @include media-breakpoint-up(md) {
    text-align: left;
  }
  img {
    width: 147px;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  a {
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
}

nav.legal ul {
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  @include media-breakpoint-up(md) {
    justify-content: flex-start;
  }
  li {
    margin-right: 0.5rem;
  }
  a {
    font-size: 0.8rem;
    text-decoration: underline;
  }
}

nav.footer {
  margin-top: 3.8rem;
}

nav.footer .menu-menu-container>.menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  @include media-breakpoint-up(md) {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  a {
    color: $blue;
  }
}

nav.footer .sub-menu {
  text-align: center;
  opacity: 0.8;
  font-size: 0.9rem;
  li::before {
    content: "> ";
    font-size: 0.8rem;
    color: $blue;
  }
  .sub-menu {
     li::before {
      content: "- ";
       color: $blue;
      font-size: 0.8rem;
    }
  }
  @include media-breakpoint-up(md) {
    text-align: left;
    padding-left: 0.4rem;
  }
}

nav.footer li {
  margin-right: 2rem;
  .sub-menu li  {
    margin-right: 0;
  }
}

.footer-circles {
  background-image: url(images/dot.png);
  background-size: 23px;
  background-position-y: 10rem;
  height: 300px;
  margin-top: 2rem;
}