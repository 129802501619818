/* _overview.scss */

.overview {
  ul {
    list-style-image: none !important;
  }
  li {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  h2 {
    margin-top: 0;
  }

}

