/* _header.scss */

header {
  background: #101845; 
  background: -moz-linear-gradient(left, #101845 0%, #101845 50%, #ffffff 50%, #ffffff 100%); 
  background: -webkit-linear-gradient(left, #101845 0%,#101845 50%,#ffffff 50%,#ffffff 100%);
  background: linear-gradient(to right, #101845 0%,#101845 50%,#ffffff 50%,#ffffff 100%);
  .container {
    background-color: $white;
  }
}

.logo {
  a {
    height: 100%;
    width: 100%;
    display: block;
  }
  background-color: $blue;
  img {
    padding: 1rem;
    padding-left: 0;
    width: 12rem;
  }  
}

nav.main .menu {
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
  position: relative;
  > li {
    padding-bottom: 15px;
  }
  > li > a:hover, > li.current-menu-item > a, > li.current-menu-parent > a, > li.current-page-ancestor > a   {
    border-bottom: 3px solid $green;
  }
  > .menu-item > .sub-menu > li:last-child {
    margin-left: 2rem;
  }
  .sub-menu {
    position: absolute;
    display: none;
    background-color: $white;
    padding: 1rem;
    z-index: 11 !important;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
    a {
      color: $blue-light;
      display: block;
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
    }
    .sub {
      color: $blue; 
      font-size: 0.8rem;
      line-height: 1;
      display: block;
      margin-bottom: 0.5rem;
    }
    .sub-menu {
      flex-direction: column;
      position: static;
      box-shadow: 0 0 0 transparent;
      padding: 0;
    }
  }
  a {
    font-size: 1.1rem;
     &:hover {
      text-decoration: none; 
    }
  }
}

nav.meta {
  margin-top: 0.5rem;
  display: flex;
  justify-content: space-between;
  #menu-meta-menu {
    display: flex;
     li {
       margin-right: 1rem;
     }
  }
  a {
    color: $blue-light;
    font-size: 0.8rem;
  }
}

nav.meta, nav.main .menu {
  display: none; 
  @include media-breakpoint-up(lg) {
    display: flex; 
  }
}

nav.main>.menu-menu-container>.menu>li>.sub-menu>li {
  @include media-breakpoint-up(lg) {
    flex: 1;
  }
}

.burger-button {
  display: block; 
  margin-right: auto;
  font-weight: bold;
  font-size: 1.4rem;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  height: 25px;
  margin-top: auto;
  margin-bottom: auto;
  @include media-breakpoint-up(lg) {
    display: none; 
  }
  img {
    width: 25px;
    height: auto;
    margin-right: 10px;
    position: relative;
    top: -3px;
  }
}

.overlay-mobile-menu { 
  position: fixed;
  height: 100%;
  width: 100%;
  padding-top: 4.5rem;
  text-align: center;
  transform: translate3D(0, -100%, 0);
  background-color: $green; 
  transition: 0.2s transform;
  top: 0;
  left: 0;
  
  ul {
    padding-left: 0;
  }
  h3 {
    font-size: 2.2rem;
    margin-bottom: 0.9rem;
    color: $white;
  }
  li {
    margin-bottom: 0.7rem;
    list-style-type: none;
  }
  a {
    font-size: 1.3rem;
    color: $white;
    &:hover {
      text-decoration: none;
      color: $blue-light;
    }
  }
  .sub-menu {
    display: none;
    a {
      font-size: 1.2rem;
    }
  }
  .close-btn {
    font-size: 3rem;
    opacity: 0.7;
    font-weight: 100;
    position: absolute;
    right: 20px;
    top: 0;
  }
}

.sub-menu.opened {
  display: block;
  margin-bottom: 1.5rem;
  opacity: 0.8;
  margin-top: 1rem;
}

.overlay-mobile-menu.open {
  transform: translate3D(0, 0, 0); 
} 

nav.main .menu li:hover .sub-menu {
  display: flex;
  transition: 0.3s;
  width: 70%;
  margin-top: 15px;
}


#menu-language-switcher, #menu-language-switcher-1  {
  display: flex;
  li {
   
    a {
      color: $grey;
      font-size: .8rem;
    }
  }
  li.wpml-ls-current-language {
    a {
      color: $blue-light;
    }
  }
  li:last-child::before {
    content: "|";
    color: $grey;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
  }
}

 #menu-language-switcher-1 {
   justify-content: center;
   @include media-breakpoint-up(md) {
      justify-content: flex-start;
    }
 }

.carousel-caption {
  top: 0;
  text-align: left;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-left: 0.9rem;
  @include media-breakpoint-up(lg) {
    width: 870px;
  }
  h1 {
    width: 15rem;
    font-weight: bold;
    font-size: 2rem;
    @include media-breakpoint-up(lg) {
      font-size: 2.8rem;
    }
  }
  p {
    font-family: $font-family-light;
  }
}

.carousel-indicators {
  bottom: 1.5rem;
  text-align: left;
  left: 0;
  right: 0;
  margin: 0 auto;
  padding-left: 2rem;
  justify-content: flex-start;
  @include media-breakpoint-up(lg) {
    width: 870px;
  }
  li {
    background-color: $white;
  }
  .active {
    background-color: $blue-light;
  }
}

.contact-box  {
  position: fixed;
  z-index: 2;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  top: 60%;
  background-color: $blue;
  height: 130px;
  width: 40px;
  opacity: 0.9;
  @include media-breakpoint-up(lg) {
    width: 62px;
    height: 170px;
    opacity: 1;
    top: 40%;
  }
  .icon img {
    width: 25px;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    @include media-breakpoint-up(lg) {
        width: 38px;
    }
  }
  .text {
    transform: rotate(-90deg);
	  color: $white;
  }
}

.points-animation-container {
  position: absolute; 
  z-index: 5;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: none;
  @include media-breakpoint-up(lg) {
    width: 870px;
    display: block;
  }
  ul {
    margin-left: auto;
    width: 24rem;
    margin-top: 3rem;
  }
  li {
    width: 10px;
    height: 10px;
    margin: 1rem;
    background-color: $white;
    border-radius: 10px;
    display: inline-block;
    opacity: 0;
    transition: 0.3s opacity;
    box-shadow: 0 0 4px rgba(16, 24, 69, 0.44);
  }
  .blink-red {
    background-color: red;
    animation: blink 0.6s linear infinite;
  }

  .blink-green {
    background-color: $green;
    animation: blink 0.6s linear infinite;
  }

  @keyframes blink {
    50% {opacity: 0;}
  }
}