/* _typography.scss */

$font-family-light: 'URW Geometric W01 Light', sans-serif;
$font-family-regular: 'URW Geometric W01 Regular', sans-serif;
$font-family-bold: 'URW Geometric W01 Bold', sans-serif;

h1, h2, h3, h4, h5 {
  font-family: $font-family-light;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}


p, li, a {
  font-family: $font-family-regular;
}

blockquote p {
  font-family: $font-family-light;
}


