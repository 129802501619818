/* _product.scss */

.single-product {
  ul.download-file-container {
    box-shadow: 0 0 10px $grey;
    padding: 1rem;
    padding-left: 2rem;
    list-style-image: none;
    li {
      display: flex;
      align-items: center;
      border-bottom: 1px solid $blue-light;
      margin-bottom: 1rem;
      padding-bottom: 0.5rem;
      a {
        font-size: 0.9rem;
      }
    }
    li::before {
    content: url(images/file-icon.svg); 
    width: 26px;
    display: inline-block;
    margin-right: 1rem;
    }
  }
  h3 {
    font-size: 1.4rem;
    margin-top: 0.4rem;
  }
}


.introduction {
  text-align: center;
  font-size: 2rem;
  p {
    font-family: $font-family-light;
    line-height: 1.2;
    a {
      color: $blue;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $blue;
      }
    }
  }
}

.image-video-text {
  img {
    width: 100%;
  }
}

.product-carousel .carousel-caption {
  h1 {
    margin-top: 0;
    width: 20rem;
  }
  .product {
    height: 9rem!important;
    width: auto !important;
    @include media-breakpoint-up(lg) {
      height: 15rem!important;
    }
  }
}