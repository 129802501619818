/* *****************************************************************
 *  Theme:    Agnosys
 *  Author:   Rubikon
 *  URI: http://www.rubikon.at
 *  ***************************************************************** */

@import '../bootstrap/scss/variables.scss';
@import '../bootstrap/scss/mixins/breakpoints.scss';
@import '_partials/variables.scss';
@import '_partials/typography.scss';
@import '_partials/header.scss';
@import '_partials/start.scss';
@import '_partials/standard.scss';
@import '_partials/product.scss';
@import '_partials/overview.scss';
@import '_partials/search.scss';
@import '_partials/footer.scss';

html {
  font-size: 14px;

  @include media-breakpoint-up(sm) {
    font-size: 15px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 16px;
  }

  @include media-breakpoint-up(xl) {
    font-size: 16px;
  }
}

body {
  font-family: $font-family-regular;
  color: $blue;
}

p {}

ul {
  padding-left: 0;
  margin-bottom: 0;
}

li {
  list-style-type: none;
}

a {
  color: $black;

  &:hover {
    color: inherit;
    text-decoration: underline;
  }

  &:visited {}

  &:focus {
    color: inherit;
    text-decoration: none;
  }

  &:active {}
}

img {
  max-width: 100%;
  height: auto;
}

h1 {}

.container {
  @include media-breakpoint-up(lg) {
    width: 870px;
  }
}

.stripline {
  height: 20px;
  width: 100%;
  background: url(images/dot.png) repeat-x left center;
  background-size: 23px;
  margin-top: 1.5rem;
  margin-bottom: 0;
}

.fade-in {
  display: block !important;
  transition: 1s;
}

section.container {
  ul {
    padding-left: 1.1rem;
    list-style-image: url(images/list-icon.png);
  }
}

blockquote {
  font-size: 1.3rem;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  line-height: 1.2;

  @include media-breakpoint-up(lg) {
    font-size: 2rem;
    text-align: center;
    width: 90%;
  }

  &:before {
    display: inline;
    content: "„";
  }

  &:after {
    display: inline;
    content: "“";
  }

  p {
    display: inline;
  }
}

.standard blockquote {
  margin-top: 1rem;
}

.author {
  color: $blue-light;
  font-size: 1rem;
  display: block;
  font-family: $font-family-light;
  margin-top: 0.7rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.3rem;
  }
}

.breadcrumbs {
  font-size: 0.8rem;
  position: relative;
  top: -22px;
  color: $blue-light;

  a {
    color: $blue;
  }
}

.wpcf7-form label {
  width: 100%;
}

.wpcf7-text {
  height: 2.5rem;
  border: 1px solid $grey-light;
  width: 100%;
}

.wpcf7-textarea {
  height: 8.5rem;
  border: 1px solid $grey-light;
  width: 100%;
}

.button,
.wpcf7-submit {
  background-color: $blue-light;
  padding: 0.5rem 1.5rem 0.5rem 1.5rem;
  color: $white;
  border-radius: 5px;
  border: 0;
  margin-top: 1rem;
  display: inline-block;

  &:hover {
    text-decoration: none;
    color: $white;
    opacity: 0.8;
  }

  &:focus {
    outline: none !important;
  }

  &:active {
    opacity: 0.6;
  }
}

.wpcf7-submit {
  margin-top: 0;
}

div.wpcf7-response-output {
  margin: 0 !important;
  font-size: 1rem;
  padding-top: 0.6rem !important;
  padding-bottom: 0.6rem !important;
}

div.wpcf7-validation-errors {
  background-color: red;
  color: $white;
  border: 0 !important;
}

div.wpcf7-mail-sent-ok {
  background-color: green;
  color: $white;
  border: 0 !important;
}


/* referenzmap */
.refMapBlock {
  height: 2000px;
  width: 100%;
  position: relative;
  float: none;
  margin: -32px 0 -79px 0;
}

.Refmap .standard .stripline {
  display: none
}

.text_blue,
.text_blue a {
  color: $blue-light;
}

.text_green,
.text_green a {
  color: $green;
}

.MapInfoLable {
  font-family: URW Geometric W01 Light, sans-serif;
  position: static;
  z-index: 999;
  margin-top: 3rem;

  @include media-breakpoint-up(lg) {
    position: absolute;
  }
}

.MapInfoLable h1 {
  font-size: 28px;
  margin: 0 0 20px 0;
}

.MapInfoLable h3 {
  font-size: 16px;
  font-weight: bold;
  font-family: URW Geometric W01 Regular, sans-serif;
  margin: 0 0 0 0;
}

.MapInfoLable .hqInfo {
  font-size: 16px;
  font-family: URW Geometric W01 Regular, sans-serif;
}

.hqInfo {
  margin: 0 0 0 16px;
}

.MapInfoLine {
  margin: 0px 0 5px 0;
}

.MapInfoLineHQ {
  margin: 20px 0 0 0;
  line-height: 22px
}

.MapInfoLineHQ a {
  text-decoration: underline
}

.MapInfoLable .bullet {
  width: 11px;
  height: 11px;
  float: left;
  margin: 4px 5px 0 0;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  border-radius: 100px;
}

.square {
  width: 11px;
  height: 11px;
  float: left;
  margin: 4px 5px 0 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  border-radius: 0px;
}

.bullet_blue {
  background: #0091D3;
}

.bullet_green {
  background: #B1CD42;
}

.bullet_black {
  background: #000000;
}

.worldmapInner {
  height: 2000px;
  width: 1360px;
  max-width: 100%;
  margin: 0 auto;
  position: relative;

}

img.worldmap2 {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  opacity: .15 !important;
  width: 100%;
}

.markerItembox {
  position: absolute;
  z-index: 5;
  width: 15px;
  height: 15px;
  margin: 35px 0 0 10px;
}

.mapmarker {
  z-index: 9999 !important;
  display: none;
  position: absolute;
  z-index: 1;
  cursor: pointer;
  width: 15px;
  height: 15px;
  -moz-transition: all 250ms;
  -o-transition: all 250ms;
  transition: all 250ms;
  left: -12px;
  top: -33px;

}

.markerItembox:hover .mapmarker {
  -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.21);
  -moz-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.21);
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.21);
}

.ReferenzDetailLayer {
  position: fixed;
  right: -1000%;
  top: 20%;
  background: #ffffff;
  z-index: 999999;
  width: 374px;
  height: auto;
  opacity: 0;
  -webkit-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.11);
  -moz-box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.11);
  box-shadow: 0px 0px 10px 0px rgba(50, 50, 50, 0.11);
  -webkit-transition: opacity 350ms;
  -moz-transition: opacity 350ms;
  -o-transition: opacity 350ms;
  transition: opacity 350ms;
}

.ReferenzDetailLayerOn {
  position: fixed;
  right: 20%;
  top: 20%;
  opacity: 1;
}

.ReferenzDetailLayerInner {
  width: 90%;
  margin: 20px auto 30px auto;
}

.ReferenzDetailLayer h2 {
  font-size: 28px;
  margin: 0 0 20px 0;
}

.ReferenzDetailLayer h3 {
  font-size: 16px;
  font-weight: bold;
  font-family: URW Geometric W01 Regular, sans-serif;
  margin: 0 0 0 0;
}

.refkontaktItem {
  margin: 0 0 20px 0;
  width: 100%;
}

.closeLayer {
  display: block;
  cursor: pointer;
  opacity: 0.2;
  float: right;
  margin: 0 0 0 0;
  width: 30px;
  height: 30px;
  -webkit-transition: opacity 350ms;
  -moz-transition: opacity 350ms;
  -o-transition: opacity 350ms;
  transition: opacity 350ms;
}

.closeLayer:hover {
  opacity: 1;
}

.refCount {
  cursor: pointer;
  position: absolute;
  color: #ffffff;
  top: -36.5px;
  left: -8px;
  z-index: 99999;
  font-size: 13px;

}

.blue-text {
  color: #0091D3 !important;
}

.privacy-policy-notice {
  font-size: 0.8rem;

  a {
    color: $blue-light;
  }
}


/* Styles from Gerald */
.big-box-image .tooltip-container.facility {
  height: auto;
  width: 560px;
  max-width: 100%;
}

@media (max-width:767px) {}

.contact-box {
  z-index: 10;

}

.single-product ul.download-file-container {
  margin-bottom: 1rem;
}

.tooltip-container .tooltip-custom p {
  display: block;
  position: absolute;
  top: 45%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  width: 100%;

  font-family: URW Geometric W01 Regular, sans-serif;
  /* BODL ist in IE 10 nicht sichtbar */

}

.tooltip-container .tooltip-custom.bottom p {
  top: calc(50% + 1.1em);
}

.markerItembox.active {
  z-index: 66;
}

.single-product ul.download-file-container li {
  margin-bottom: 14px;
  /* IE Fix */
  padding-bottom: 7px;
  /* IE Fix */

  position: relative;
  padding-left: 3rem;
}

.single-product ul.download-file-container li a {
  padding: .75rem 0;
  display: block;
}

.single-product ul.download-file-container li::before {
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);

  display: block;

}

.ReferenzDetailLayerOn {

  max-width: 100%;
}

.disclaimer {
  position: absolute;
  left: 0;
  bottom: 0;
  padding: .25rem .75rem;
  background: rgba(255, 255, 255, .7);
  font-size: 13px;
}

.disclaimer p {
  margin: 0;
  padding: 0;
}

input[type="checkbox"]+label {
  padding-left: 1rem;
  margin-top: .25rem;
}

#mc_embed_signup input.mce_inline_error {
  border-color: #6B0505;
}

div.mce_inline_error {
  margin: 0 0 1em 0;
  padding: 5px 10px;
  background-color: #6B0505;
  font-weight: bold;
  z-index: 1;
  color: #fff;
}

#mc_embed_signup a {
  text-decoration: underline;

}

.overlay-mobile-menu.open nav.meta {
  display: block;
}

.overlay-mobile-menu.open #menu-language-switcher-1 {

  -ms-flex-pack: center;
  justify-content: center;
}

.overlay-mobile-menu.open #menu-language-switcher-1 li.wpml-ls-current-language a,
.overlay-mobile-menu.open nav.meta a {
  color: white;

}

.overlay-mobile-menu {
  overflow: scroll;

}

.card {
  display: block;

}

.MapInfoLable {
  z-index: -1;

}

#carousel-standard .carousel-item img,
.carousel-item img.fade-in {
  opacity: .8;
}

#carousel-standard .carousel-item {
  background: black;
}

.sidebar {
  border-right: 1px solid #0091d3;
  padding: 1rem;
  position: fixed;
  top: 10rem;
  padding-right: 3rem;
}

.sidebar a {
  text-decoration: underline;


}

.filename {
  color: #0091d3;
}

.sidebar a:hover {
  color: #b1cd42;
}

@media (max-width:767px) {
  .ReferenzDetailLayerOn {
    right: 0;
  }

  .sidebar {
    position: relative;

    top: 0;
    padding: 0;
    padding-top: 3rem;
  }
}

@media (min-width:992px) {
  .product-carousel .carousel-caption .product {
    height: 13rem !important;
  }
}

//termin template

.termin-card {
  background-color: #fff;

  .icon-container {
    display: flex;
    align-items: start;
    margin-bottom: 20px;

    img {
      width: 23px;
      margin-left: 10px;
      margin-right: 20px;
    }
  }

  hr {
    border-color: $blue-light;
  }

  .link {
    margin-top: 0px;
    display: flex;
    justify-content: space-between;
    margin-left: 52px;
    
  }

  .termin {
    padding: 20px 20px 20px 0px;
    border-radius: 5px;
    &:hover {
      background-color: #ededed;
    }
  }
}

/*
Yolo man, i just kicked out your //Object fit fallback because of lack of IE!
*/