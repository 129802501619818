/* _start.scss */

.start {
  p {
    a {
      color: $blue;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $blue;
      }
    } 
  }
}

#carousel-start {
  height: 400px;
  width: 100%;
  margin-bottom: 2rem;
  @include media-breakpoint-up(lg) {
    height: 700px;
    width: 100%;
  }

  .carousel-inner, .carousel-item {
    height: 100%;
    width: 100%;
  }

  .carousel-item img {
    height: 400px;
    width: 100%;
    object-fit: cover;
    display: none;
    @include media-breakpoint-up(lg) {
      height: 700px;
      width: 100%;
    }
  }

}

#carousel-content {
  height: auto;
  margin-bottom: 2rem;
  margin-top: 2rem;

  .carousel-inner {
    display: flex;
    align-items: center;
  }

   .carousel-inner, .carousel-item {
      height: 100%;
      width: 100%;
    }

  .carousel-item-next, .carousel-item-prev, .carousel-item.active {
    flex-direction: column;
  }
  p {
    a {
      color: $blue;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
        color: $blue;
      }
    }
  }
}

#carousel-content .carousel-item {
  flex-direction: column;
  justify-content: center;
  @include media-breakpoint-up(lg) {
    min-height: 250px;
  }
  p {
    text-align: center;
    margin: 0 auto;
  }
  img {
    height: auto;
    width: 100%;
    object-fit: cover;
    margin: 0 auto;
    @include media-breakpoint-up(lg) {
      height: auto;
      width: 100%;
    }
  }
}